import { Container } from "./styles";
// import DownloadApp from '../../assets/download.png'


export function Project() {
  return (
    <Container id="project">
      
    </Container>
  );
}