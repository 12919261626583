import { Container } from "./styles";
import Jamihtee from "../../assets/Portfolio.png";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Hakkımda</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          32 yaşındayım. Ankara'da yaşıyorum.
          Aktif olarak Full-Stack Developer olarak çalışıyorum. Bir .NET developer'ım ayrıca hobi ve proje bazlı olarak Unity üzerinden oyun geliştiriyorum.Ayrıca Elektrik-Elektronik Mühendisiyim.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
          Uzmanlık alanım genellikle bu alanda,çalıştığım yerlerde genellikle MSSQL(T-SQL) veri tabanını kullanarak ORM aracı olarak Entity Framework Core ile ilişkilendirip MVC projeleri veyahut API servisleri yazdığım oldu.Front-End Geliştirme Sürecinde HTML,CSS,JS kullanmaktayım genel olarak bunu tercih ediyorum.Ancak React da biliyorum. Ayrıca Unity oyun motorunu kullanarak C# programlama ile WebGL üzerinde simülasyon ve oyun geliştirme projelerinde yer aldım.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          Benimle ilgili detaylı bilgiyi CV'mi indirerek ulaşabilir, benimle iletişime geçmek istiyorsanız iletişim bölümünden bana e-posta yazabilir ya da iletişim bilgilerinden bana ulaşabilirsiniz.</p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={Jamihtee} alt="Jamihtee" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}
